import React, { createContext, useContext, useEffect, useState } from "react"

const DateContext = createContext()

export const useDateContext = () => {
  return useContext(DateContext)
}

export const DateProvider = ({ children }) => {
  const [selectedDates, setSelectedDates] = useState({
    checkInDate: null,
    checkOutDate: null,
  })
  const [rangeState, setRangeState] = useState(false)

  useEffect(() => {
    if (selectedDates.checkInDate && selectedDates.checkOutDate) {
      setRangeState(true)
    } else {
      setRangeState(false)
    }
  }, [selectedDates])

  const value = {
    selectedDates,
    setSelectedDates,
    rangeState,
    setRangeState,
  }

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>
}
