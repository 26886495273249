module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Swiss Alpine Homes","short_name":"SwissAlpineHomes","description":"Personalised accommodation in Grindelwald, Lauterbrunnen & Interlaken","start_url":"/","background_color":"#ffffff","theme_color":"#008489","display":"standalone","icon":"src/assets/svgs/swiss-alpine-homes-favicon.svg","lang":"en","localize":[{"start_url":"/de/","lang":"de","name":"Swiss Alpine Homes","short_name":"SwissAlpineHomes","description":"Personalisierte Unterkunft in Grindelwald, Lauterbrunnen & Interlaken"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5877c7932d86afc3363aa068838453c7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.swiss-alpine-homes.com/graphql","schema":{"perPage":10,"timeout":2147483647,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"__all":{"limit":2000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
