import React from "react"
import GlobalContextProvider from "@context/GlobalContext"
import { DateProvider } from "@context/DateContext"

export default function RootLayout(props) {
  const { children } = props

  return (
    <>
      <GlobalContextProvider>
        <DateProvider>{children}</DateProvider>
      </GlobalContextProvider>
    </>
  )
}
